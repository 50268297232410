import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

function FAQ() {
  const d = useStaticQuery(
    graphql`
      {
        contentfulFaqSmartSeller {
          title
          content {
            answer
            question
          }
        }
      }
    `
  );
  const data = d.contentfulFaqSmartSeller;
  const [openFAQ, setOpenFAQ] = useState([]);

  return (
    <div className="flex justify-center">
      <div className="max-w-6xl w-full lg:w-3/5 grid gap-3.5 items-center mb-24">
        <h3 className="text-[32px] text-center font-bold">FAQ</h3>
        {data.content.map((item, index) => (
          <div
            className="bg-white py-3 px-4 grid cursor-pointer shadow-lg rounded-md"
            key={`faq-${index}`}
            onClick={() => {
              if (openFAQ.includes(index)) {
                setOpenFAQ(openFAQ.filter((number) => number !== index));
              } else {
                setOpenFAQ([...openFAQ, index]);
              }
            }}
          >
            <span
              className="font-bold "
              dangerouslySetInnerHTML={{ __html: item.question }}
            />
            <span
              className={`${
                !openFAQ.includes(index) ? "h-0" : "h-full mt-3"
              } overflow-hidden transition-all text-gray-400`}
              dangerouslySetInnerHTML={{ __html: item.answer }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

FAQ.propTypes = {
  data: PropTypes.object,
};

export default FAQ;
