import React from "react";
import Button from "../components/button";
import WindowSize from "../components/window-size";

import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import Image from "../components/image";

import {
  UilCheckSquare,
  UilCommentQuestion,
  UilStore,
} from "@iconscout/react-unicons";
import FAQ from "./faq";

function Icon({ icon }) {
  let color = `#1155cc`;

  switch (icon) {
    case "IconUilCheckSquare":
      return (
        <UilCheckSquare className="mx-auto md:mx-0" size="48" color={color} />
      );
    case "IconUilCommentQuestion":
      return (
        <UilCommentQuestion
          className="mx-auto md:mx-0"
          size="48"
          color={color}
        />
      );
    case "IconUilStore":
      return <UilStore className="mx-auto md:mx-0" size="48" color={color} />;
    default:
      return;
  }
}

function Cta({ url, hideFAQ = false }) {
  const data = useStaticQuery(
    graphql`
      {
        contentfulCta {
          title
          content {
            icon
            text
            desc
          }
          cta {
            color
            text
            url
          }
        }
      }
    `
  );

  const size = WindowSize();

  const content = (
    <div className="flex">
      <div className="hidden md:block w-1/3" style={{}}></div>

      <div className="p-4 md:p-8 w-full md:w-2/3">
        <h2 className="max-w-xl text-2xl md:text-3xl mb-6 md:mb-10 leading-tight font-display text-gray-800 text-center md:text-left">
          {data.contentfulCta.title}
        </h2>

        <div className="flex flex-col md:flex-row mb-2 md:space-x-4">
          {data.contentfulCta.content.map((item) => (
            <div
              className="flex flex-row md:flex-col space-x-3 md:space-x-0 md:flex-1 mb-6"
              key={item.text}
            >
              <div>
                <Icon icon={item.icon} />
              </div>

              <p className="text-left md:my-4 text-gray-800 font-sans leading-tight text-normal md:text-lg">
                {item.text}
              </p>
            </div>
          ))}
        </div>

        <div className="flex flex-col md:flex-row justify-center md:justify-start w-full">
          {data.contentfulCta.cta.map((item, idx) => (
            <Button
              key={item.text}
              text={item.text}
              size="lg"
              color={item.color}
              url={idx === 0 && url ? url : item.url}
              className="cta_bottom mb-2"
            />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <section className="py-8 md:py-12 bg-gray-50 dark:bg-gray-800 px-4">
      {!hideFAQ && <FAQ />}

      <div className="w-full max-w-6xl mx-auto rounded-2xl border border-gray-100 dark:border-gray-800">
        {size.width >= 768 ? (
          <Image
            style={{
              backgroundRepeat: `no-repeat`,
              backgroundPosition: `-9rem center`,
              backgroundSize: `47%`,
              borderRadius: `1rem`,
              backgroundColor: `#FACF6C`,
              overflow: `hidden`,
            }}
            filename="macbook-pro-mockup-smartseller.png"
            isBackground={true}
            content={content}
          />
        ) : (
          <div
            style={{ backgroundColor: `#FACF6C` }}
            className="rounded-2xl bg-secondary"
          >
            {content}
          </div>
        )}
      </div>
    </section>
  );
}

Icon.propTypes = {
  data: PropTypes.object,
  icon: PropTypes.string,
};

Cta.propTypes = {
  url: PropTypes.string,
  hideFAQ: PropTypes.bool
};

export default Cta;
