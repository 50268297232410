import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

const Image = (props) => (
    <StaticQuery
        query={graphql`
            {
                images: allFile {
                    edges {
                        node {
                            relativePath
                            name
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                    layout: FULL_WIDTH
                                    formats: [AUTO, WEBP]
                                )
                            }
                            extension
                            publicURL
                        }
                    }
                }
            }
        `}
        render={(data) => {
            const image = data.images.edges.find((n) => {
                return n.node.relativePath.includes(props.filename);
            });

            if (!image) {
                return null;
            }

            if (!image.node.childImageSharp && image.node.extension === "svg") {
                return <GatsbyImage src={image.node.publicURL} />;
            }

            //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}

            if (props.isBackground == true) {
                const pluginImage = getImage(image.node.childImageSharp);

                return (
                    <BgImage
                        image={pluginImage}
                        alt={props.alt}
                        className={props.className}
                        style={props.style}
                    >
                        {props.content}
                    </BgImage>
                );
            }

            return (
                <GatsbyImage
                    alt={props.alt}
                    image={image.node.childImageSharp.gatsbyImageData}
                    className={props.className}
                    fadeIn={false}
                    loading="eager"
                />
            );
        }}
    />
);
Image.defaultProps = {
    isBackground: false,
    content: null,
    style: null,
};
Image.propTypes = {
    filename: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    isBackground: PropTypes.bool,
    content: PropTypes.object,
    style: PropTypes.object,
};

export default Image;
